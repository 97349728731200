import React from 'react';

import { useEFM } from '@/components/Ajax';

export default function useGetWebhooks({ onSuccess }) {
	return useEFM('/integrations/webhooks/list', {}, false, false, null, {
		onSuccess: onSuccess,
		disableCache: true,
	});
}
