export function webhookBase({
	id,
	webhook = {},
	domain,
	project_id,
	webhookType,
	eventType,
	surveyId,
} = {}) {
	return {
		name: webhook.name ?? '',
		webhook_id: id,
		webhook_type: webhookType ?? webhook.webhook_type ?? 'general',
		event_type: eventType ?? webhook.event_type ?? 'created',
		active: webhook.webhook_id ? webhook.active : true,
		conditions: webhook.conditions ?? [],
		parameters: webhook.parameters ?? {
			all: true,
			aliasses: [],
		},
		endpoint: webhook.endpoint ?? [''],
		trello_apicreds:
			webhook.trello_apicreds ?? webhookType === 'trello' ? [trelloBase()] : [],
		asana_apicreds:
			webhook.asana_apicreds ?? webhookType === 'asana' ? [asanaBase()] : [],
		extra_fields:
			webhook.extra_fields ?? ['salesforce-form', 'html-form'].includes(webhookType)
				? [htmlFormBase()]
				: [], //salesforce & htmlform
		prefilled_fields:
			webhook.prefilled_fields ?? ['salesforce-form', 'html-form'].includes(webhookType)
				? [htmlFormBase()]
				: [], // salesforce & htmlform
		feedback_field: webhook.feedback_field ?? '', // salesforce & htmlform
		org_id: webhook.org_id ?? '', // salesforce
		sf_form: webhook.sf_form ?? 'web-to-case', // salesforce ( startvalue )
		domain: webhook.domain ?? domain,
		project_id: webhook.project_id ?? project_id,
		survey_id: surveyId ?? webhook.survey_id ?? null,
		all_data: webhook.webhook_id ? webhook.all_data : true,
		all_project_data: (eventType === 'user_init' || webhook.all_project_data) ?? false,
		api_creds: webhook.api_creds ?? [],
		custom_header: webhook?.custom_header?.[0] ?? {
			//custom_header is the same for all endpoints within the hook, but returns an array, so we go for the first option in the array
			enabled: false,
			secret_token: '',
		},
		...(webhook.timestamp && {
			timestamp: webhook.timestamp,
		}),
	};
}

export function conditionBase() {
	return {
		datafieldid: '',
		condition: 'e',
		target: '',
	};
}

export function customHeaderBase() {
	return {
		enabled: false,
		header_name: 'X-Mopinion-Token',
		secret_token: '',
	};
}

export function paramBase() {
	return {
		datafield: '',
		label: '',
		var_type: '',
	};
}

export function htmlFormBase() {
	return {
		key: '',
		value: '',
	};
}

export function asanaBase({ apiKey = '', refreshToken = '' } = {}) {
	return {
		apiKey,
		workspaceId: '',
		workspaceName: '',
		projectId: '',
		projectName: '',
		refreshToken,
	};
}

export function trelloBase({ username = '', key = '', token = '' } = {}) {
	return {
		username,
		key,
		token,
		idBoard: '',
		nameBoard: '',
		idList: '',
		nameList: '',
	};
}

export function jiraBase(auth_id = '') {
	return {
		projectKey: '',
		auth_id,
		userId: '',
		issueType: '',
		parent_id: '',
		labels: [],
	};
}

export function basecampBase(auth_id = '') {
	return {
		auth_id,
		project_id: '',
		todo_set_id: '',
		todo_list_id: '',
		assignee: '',
	};
}
