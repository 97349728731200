import React, { useContext, createContext } from 'react';

import { useTimeTravel } from '@/hooks';
import useGetWebhooks from './useGetWebhooks';
import useGetAuthentications from './useGetAuthentications';

const WebHookContext = createContext({});

export default WebHookContext;

function reducer(state, action) {
	switch (action.type) {
		case 'init_config':
			state.hooks = action.payload;
			return;

		case 'add_filled_rule':
			state.hooks.push(action.payload.webhook);
			return;

		case 'update_rule':
			const ruleIndex = state.hooks.findIndex(
				rule => rule.webhook_id === action.payload.webhook.webhook_id
			);
			state.hooks[ruleIndex] = action.payload.webhook;
			return;

		case 'move_rule':
			state.hooks.splice(
				action.payload.destinationIndex,
				0,
				state.hooks.splice(action.payload.sourceIndex, 1)[0]
			);
			return;

		case 'delete_rule':
			const index = state.hooks.findIndex(rule => rule.webhook_id === action.payload.id);
			state.hooks.splice(index, 1);
			return;

		default:
			return;
	}
}

export function WebHookContextProvider({ value, children }) {
	const [webhooksList, webhooksListLoading] = useGetWebhooks({
		onSuccess: response => {
			if (response?.hooks?.length) {
				dispatch({
					type: 'init_config',
					payload: response.hooks,
				});
			}
		},
	});

	const [{ authentications = [] }, authLoading, authError, loadAuth] =
		useGetAuthentications();

	const { state, dispatch, timeline, doUndo, doRedo, doReset } = useTimeTravel(reducer, {
		hooks: [],
	});

	return (
		<WebHookContext.Provider
			value={{
				state: {
					hooks: state.hooks,
					loading: webhooksListLoading,
					auths: authentications,
				},
				api: {
					dispatch,
					doUndo,
					doRedo,
					loadAuth,
				},
			}}
		>
			{typeof children === 'function' ? children({ state }) : children}
		</WebHookContext.Provider>
	);
}

export function useWebHookContext() {
	return useContext(WebHookContext);
}
