import React from 'react';
import { useAjaxForm } from '@/components/Ajax';

export default function useDeleteWebhook({ id, onSuccess, ...props }) {
	return useAjaxForm({
		url: '/integrations/ajax/delete-webhook',
		data: { id },
		onSuccess,
		...props,
	});
}
