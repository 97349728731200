import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { WEBHOOK_TYPES } from './constants';
import WebHookAddItDialog from './WebHookAddItDialog';
import useDeleteWebhook from './useDeleteWebhook';
import useSaveWebhook from './useSaveWebhook';
import { useWebHookContext } from './WebHookContext';
import {
	ActionButton,
	ActionIconButton,
	Alert,
	Box,
	Chip,
	Collapse,
	Grid,
	KnowledgeBaseLink,
	Link,
	SimpleDialog,
	SystemElementCardBase,
	Typography,
} from '@/components/Layout';
import { useDataSourceContext } from '@/components/DataSources';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	divider: {
		margin: theme.spacing(2, 0),
	},
	activeChip: {
		backgroundColor: props =>
			props.active ? theme.palette.secondary.light : theme.palette.error.light,
		marginLeft: theme.spacing(1),
	},
	disabledChip: {
		opacity: 0,
	},
	cardMedia: {
		backgroundSize: '60%!important',
	},
	padding: {
		padding: theme.spacing(0.5),
		paddingTop: theme.spacing(1),
	},
	spacing: {
		paddingBottom: theme.spacing(1),
	},
	removed: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.9)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: theme.spacing(3),
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: 10,
	},
	alignMiddleInline: {
		verticalAlign: 'middle',
	},
	relative: {
		position: 'relative',
	},
	expand: {
		verticalAlign: 'middle',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	alert: {
		marginTop: theme.spacing(2),
	},
	chip: {
		position: 'absolute',
		zIndex: 1000,
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
}));

function WebHookCard({ hook }) {
	const classes = useStyles(hook);
	const { state, api } = useWebHookContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();
	const source = datasource.api.getSource(hook.survey_id);
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { postForm: saveHook } = useSaveWebhook({ hook });

	const { postForm: deleteHook, loading: loadingDelete } = useDeleteWebhook({
		id: hook.webhook_id,
		onSuccess: () => {
			setDeleteOpen(false);
			api.dispatch({
				type: 'delete_rule',
				payload: {
					id: hook.webhook_id,
				},
			});
			const key = enqueueSnackbar(
				t('integrations-webhook-dialog_delete-snackbar', { name: hook.name }),
				{
					action: (
						<ActionButton
							color="secondary"
							onClick={() => {
								api.doUndo();
								saveHook();
								closeSnackbar(key);
							}}
						>
							{t`integrations-webhook-dialog_delete-undo`}
						</ActionButton>
					),
				}
			);
		},
	});
	const hasEditPermission = useHasPermission();
	const hookDefaults = WEBHOOK_TYPES.find(type => type.value === hook.webhook_type) ?? {};

	return (
		<span className={classes.relative}>
			{Object.keys(source).length === 0 &&
				hook.event_type !== 'user_init' &&
				!hook.all_project_data && (
					<div className={classes.removed}>
						<div>
							<div>
								<Typography
									variant="subtitle2"
									display="inline"
									className={classes.alignMiddleInline}
									color="textSecondary"
								>{t`integrations-webhook-message_deleted-title`}</Typography>
							</div>
							<Typography
								variant="caption"
								color="textSecondary"
							>
								{t`integrations-webhook-message_deleted-text`}
							</Typography>
							<Box mt={1}>
								<NoPermissionTooltip>
									<ActionButton
										onClick={() => setDeleteOpen(true)}
										action="delete"
										label={t`integrations-webhook-message_deleted-button`}
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>
							</Box>
						</div>
					</div>
				)}
			<SystemElementCardBase
				CardMediaClassName={classes.cardMedia}
				img={hookDefaults.img}
				title={hook.name}
				subtitle={
					hook.all_project_data
						? t`integrations-webhook-webhook_card-subtitle-all_data`
						: t(
								`integrations-webhook-webhook_card-subtitle-${
									source.survey_type === 'builder' ? 'form' : 'dataset'
								}`,
								{ name: source.name }
						  )
				}
				info={[
					{
						label: t`integrations-webhook-webhook_card-label_type`,
						value:
							hook.event_type && hook.event_type === 'user_init'
								? t`integrations-webhook-webhook_card-value_manual_by_user`
								: t`integrations-webhook-webhook_card-value_new_feedback`,
					},
					...(hook.event_type !== 'user_init'
						? [
								{
									label: t`integrations-webhook-webhook_card-label_status`,
									value: (
										<Chip
											label={
												hook.active
													? t`integrations-webhook-webhook_card-chip_active`
													: t`integrations-webhook-webhook_card-chip_inactive`
											}
											className={classes.activeChip}
											size="small"
										/>
									),
								},
						  ]
						: []),
					...(hook.webhook_type === 'asana'
						? hook.asana_apicreds.flatMap(cred => {
								return [
									{
										label: t`integrations-webhook-webhook_card-asana_workspace`,
										value: cred.workspaceName,
									},
									{
										label: t`integrations-webhook-webhook_card-asana_board`,
										value: cred.projectName,
									},
								];
						  })
						: hook.webhook_type === 'trello'
						? hook.trello_apicreds?.flatMap(cred => {
								return [
									{
										label: t`integrations-webhook-webhook_card-trello_board`,
										value: cred.nameBoard,
									},
									{
										label: t`integrations-webhook-webhook_card-trello_list`,
										value: cred.nameList,
									},
								];
						  })
						: hook.webhook_type === 'jira'
						? hook.api_creds.flatMap(cred => {
								return [
									{
										label: t`integrations-webhook-webhook_card-jira_project`,
										value: cred?.projectKey,
									},
									{
										label: t`integrations-webhook-webhook_card-jira_issue_type`,
										value: cred?.issueType,
									},
									{
										...(cred?.labels.length > 0
											? [
													{
														label: t`integrations-webhook-webhook_card-jira_labels`,
														value: cred.labels.join(', '),
													},
											  ]
											: []),
									},
								];
						  })
						: hook.webhook_type === 'basecamp'
						? hook.api_creds.flatMap(cred => {
								return [
									{
										label: t`integrations-webhook-webhook_card-basecamp_project`,
										value: cred.project_name,
									},
									{
										label: t`integrations-webhook-webhook_card-basecamp_todo`,
										value: cred.todo_list_name,
									},
									...(cred.assignee
										? [
												{
													label: t`integrations-webhook-webhook_card-basecamp_assignee`,
													value: cred.assignee_name,
												},
										  ]
										: []),
								];
						  })
						: hook.endpoint.map(point => {
								return {
									label: t`integrations-webhook-webhook_card-other_endpoint`,
									value: point,
								};
						  })),
				]}
				actions={
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<NoPermissionTooltip>
								<ActionIconButton
									tooltip={t`integrations-webhook-webhook_card-action-edit_tooltip`}
									action="edit"
									onClick={() => setEditOpen(true)}
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
							<NoPermissionTooltip>
								<ActionIconButton
									tooltip={t`integrations-webhook-webhook_card-action-delete_tooltip`}
									action="delete"
									onClick={() => setDeleteOpen(true)}
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
						</Grid>
					</Grid>
				}
			>
				<div className={classes.padding}>
					<Typography
						variant="body2"
						color="textSecondary"
					>{t`integrations-webhook-webhook_card-info-title`}</Typography>
					{hook.event_type === 'user_init' && (
						<Typography variant="body2">{t`integrations-webhook-webhook_card-info-user_init`}</Typography>
					)}
					{hook.event_type !== 'user_init' && hook.all_project_data && (
						<Typography variant="body2">{t`integrations-webhook-webhook_card-info-new_item_report`}</Typography>
					)}
					{hook.event_type !== 'user_init' && hook.all_data && (
						<Typography variant="body2">
							{t`integrations-webhook-webhook_card-info-new_item`} {source.name}
						</Typography>
					)}
					{hook.event_type !== 'user_init' && !hook.all_project_data && !hook.all_data && (
						<Grid
							container
							direction="column"
						>
							<WebhookRules hook={hook} />
						</Grid>
					)}
					{hook.webhook_type === 'ms_teams' && (
						<Alert
							severity="info"
							className={classes.alert}
						>
							<Typography variant="body2">
								<Trans
									i18nKey="integrations-webhook-addit_dialog-alert_ms_teams"
									components={{
										kbLink: (
											<KnowledgeBaseLink
												page="ms_teams_setup"
												color="inherit"
												verticalAlign="inherit"
											/>
										),
									}}
								/>
							</Typography>
						</Alert>
					)}
				</div>
			</SystemElementCardBase>

			<WebHookAddItDialog
				webhook={hook}
				key={hook.webhook_id}
				open={editOpen}
				onClose={() => setEditOpen(false)}
			/>

			<SimpleDialog
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				title={t`integrations-webhook-dialog_delete-title`}
				text={t('integrations-webhook-dialog_delete-text', {
					name: hook.name,
				})}
				dataTrackEvent="integrations_webhook_deleted"
				submit={t`integrations-webhook-dialog_delete-submit`}
				loading={loadingDelete}
				onSubmit={() => {
					deleteHook();
				}}
			/>
		</span>
	);
}

function WebhookRules({ hook }) {
	const classes = useStyles(hook);
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const conditions = Array.isArray(hook.conditions) ? hook.conditions : [];

	const [collapsed, setCollapsed] = useState(false);

	if (!conditions.length) {
		return null;
	}

	const [firstCondition, ...rest] = conditions;

	const { system_var } = datasource.api.getField(firstCondition.datafieldid);

	return (
		<>
			<Grid
				item
				xs
				className={classes.spacing}
			>
				<Trans
					i18nKey={t(
						`integrations-webhook-webhook_card-operator_${firstCondition.condition}`,
						{ system_var: system_var, target: firstCondition.target }
					)}
					components={{
						bold: <strong />,
					}}
				/>
			</Grid>
			{rest.length > 0 && (
				<>
					<Collapse
						in={collapsed}
						timeout="auto"
					>
						{conditions.map((data, conditionIndex) => {
							const { system_var } = datasource.api.getField(data.datafieldid);
							return (
								<Grid
									item
									xs
									key={'condition' + conditionIndex}
									className={classes.spacing}
								>
									<Trans
										i18nKey={t(
											`integrations-webhook-webhook_card-operator_${data.condition}`,
											{ system_var: system_var, target: data.target }
										)}
										components={{
											bold: <strong />,
										}}
									/>
								</Grid>
							);
						})}
					</Collapse>
					<Link
						color="textSecondary"
						variant="caption"
						onClick={() => setCollapsed(prev => !prev)}
						className={classes.collapse}
					>
						{collapsed
							? t`integrations-webhook-conditions-show_less`
							: t`integrations-webhook-conditions-show_more`}
						<ExpandMoreIcon
							fontSize="small"
							className={`${classes.expand} ${collapsed ? classes.expandOpen : ''}`}
						/>
					</Link>
				</>
			)}
		</>
	);
}

export default WebHookCard;
