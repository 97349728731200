export const WEBHOOK_TYPES = [
	{
		category: ['webhook', 'all_round'],
		label: 'integrations-webhook-label-JSON_hook',
		value: 'general',
		img: '/assets/img/general-icon.png',
		icon: '/assets/img/r/webhook-icon.svg',
		subtitle: 'integrations-webhook-description-JSON_hook',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['messaging', 'team'],
		label: 'integrations-webhook-label-slack',
		value: 'slack',
		img: '/assets/img/slack-icon.png',
		icon: '/assets/img/r/slack-icon.svg',
		subtitle: 'integrations-webhook-description-slack',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['messaging', 'team'],
		label: 'integrations-webhook-label-rocketchat',
		value: 'rocketchat',
		img: '/assets/img/rocketchat-icon.png',
		icon: '/assets/img/r/rocketchat-icon.svg',
		subtitle: 'integrations-webhook-description-rocketchat',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['project'],
		label: 'integrations-webhook-label-trello',
		value: 'trello',
		img: '/assets/img/trello-icon.png',
		icon: '/assets/img/r/trello-icon.svg',
		subtitle: 'integrations-webhook-description-trello',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['project'],
		label: 'integrations-webhook-label-asana',
		value: 'asana',
		img: '/assets/img/asana-icon.png',
		icon: '/assets/img/r/asana-icon.svg',
		subtitle: 'integrations-webhook-description-asana',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['messaging', 'team'],
		label: 'integrations-webhook-label-google_chat',
		value: 'googlechat',
		img: '/assets/img/googlechat-icon.png',
		icon: '/assets/img/r/google-chat-icon.svg',
		subtitle: 'integrations-webhook-description-google_chat',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['project'],
		label: 'integrations-webhook-label-jira',
		value: 'jira',
		img: '/assets/img/jira-icon.svg',
		icon: '/assets/img/r/jira-icon.svg',
		subtitle: 'integrations-webhook-description-jira',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['project'],
		label: 'integrations-webhook-label-basecamp',
		value: 'basecamp',
		img: '/assets/img/basecamp-icon.png',
		icon: '/assets/img/r/basecamp-icon.svg',
		subtitle: 'integrations-webhook-description-basecamp',
		event_types: ['created', 'user_init'],
	},
	{
		/* legacy ( legacy, sunset at dec 2025) */ category: ['team'],
		label: 'integrations-webhook-label-ms_teams_legacy',
		value: 'ms_teams',
		icon: '/assets/img/r/ms-teams-icon.svg',
		img: '/assets/img/ms_teams-icon.png',
		subtitle: 'integrations-webhook-description-ms_teams_legacy',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['team'],
		label: 'integrations-webhook-label-ms_teams',
		value: 'ms_teams_workflow',
		icon: '/assets/img/r/ms-teams-icon.svg',
		img: '/assets/img/ms_teams-icon.png',
		subtitle: 'integrations-webhook-description-ms_teams',
		event_types: ['created', 'user_init'],
	},
	{
		category: ['crm'],
		label: 'integrations-webhook-label-salesforce',
		value: 'salesforce-form',
		icon: '/assets/img/r/salesforce-icon.svg',
		img: '/assets/img/salesforce-icon.png',
		subtitle: 'integrations-webhook-description-salesforce',
		event_types: ['created'],
	},
	{
		category: ['crm'],
		label: 'integrations-webhook-label-html_form',
		value: 'html-form',
		icon: '/assets/img/r/htmlform-icon.svg',
		img: '/assets/img/htmlform-icon.png',
		subtitle: 'integrations-webhook-description-html_form',
		event_types: ['created'],
	},
];

export const SLACK_ENDPOINT = 'https://hooks.slack.com/services/';

export const ASANA_ENDPOINT = 'https://app.asana.com/api/1.0/';
export const ASANA_API_TOKEN_URL =
	'https://app.asana.com/-/oauth_authorize?response_type=code&client_id=1110681104029566&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&state=<STATE_PARAM>';

export const TRELLO_ENDPOINT = 'https://api.trello.com/1/cards';
export const TRELLO_AUTH_URL = 'https://trello.com/1/authorize';
export const TRELLO_QUERY =
	'?expiration=never&name=MopinionWebhook&scope=read,write&response_type=token&key=';

export const JIRA_ENDPOINT = 'https://api.atlassian.com/ex/jira';

export const SALESFORCE_ENDPOINTS = [
	'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
	'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
];
