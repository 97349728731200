import React, { useState, useRef } from 'react';

import FormGroup from '@material-ui/core/FormGroup';

import { useTranslation, Trans } from 'react-i18next';
import { useImmerReducer } from 'use-immer';
import { useSnackbar } from 'notistack';
import ObjectID from 'bson-objectid';

import { makeStyles } from '@/styles';

import { useWebHookContext } from './WebHookContext';
import useSaveWebhook from './useSaveWebhook';
import AsanaFlow from './AsanaFlow';
import TrelloFlow from './TrelloFlow';
import JiraRow from './JiraRow';
import BasecampRow from './BasecampRow';
import {
	conditionBase,
	customHeaderBase,
	paramBase,
	htmlFormBase,
	asanaBase,
	trelloBase,
	jiraBase,
	basecampBase,
	webhookBase,
} from './webhookUtils';
import {
	ASANA_ENDPOINT,
	ASANA_API_TOKEN_URL,
	TRELLO_ENDPOINT,
	SLACK_ENDPOINT,
	JIRA_ENDPOINT,
	SALESFORCE_ENDPOINTS,
} from './constants';

import { useAppContext } from '@/components/AppContext';
import { DataSourceSelect, DataFieldSelect } from '@/components/Select';
import {
	Alert,
	ActionButton,
	ActionIconButton,
	Box,
	Checkbox,
	Chip,
	FormLabel,
	FormControlLabel,
	Grid,
	InputAdornment,
	Typography,
	TextField,
	Link,
	SimpleDialog,
	DataFieldConditions,
	SelectionCard,
	Step,
	Stepper,
	StepLabel,
	StepButton,
	Radio,
	RadioGroup,
	KnowledgeBaseLink,
} from '@/components/Layout';
import { ValidatedField, ruleTests } from '@/components/Validation';
import AuthList from './AuthList';

import { operators } from '@/helpers';
import { WEBHOOK_TYPES } from './constants';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	blockWrapper: {
		padding: theme.spacing(0, 0.5),
	},
	stepper: {
		paddingLeft: props => (props.type ? theme.spacing(4) : 0),
		paddingRight: props => (props.type ? theme.spacing(4) : 0),
		paddingBottom: 0,
	},
	error: {
		color: theme.palette.error.main,
	},
	legacyChip: {
		position: 'absolute',
		bottom: theme.spacing(1),
		left: theme.spacing(1),
	},
}));

const pageMap = {
	preselectedWebhookType: ['connectApi', 'dataSelection'],
	default: ['selectWebhookType', 'connectApi', 'dataSelection'],
};

function isValidOrgId(value, oi) {
	return value.match(/00D/g) !== null;
}

function reducer(draft, action) {
	switch (action.type) {
		case 'reset_config':
			draft = Object.assign(draft, action.payload);
			return;

		case 'set_value':
			const { key, value, nested1, nested2, nested3 } = action.payload;

			if (typeof nested3 !== 'undefined') {
				draft[key][nested1][nested2][nested3] = value;
			} else if (typeof nested2 !== 'undefined') {
				draft[key][nested1][nested2] = value;
			} else if (typeof nested1 !== 'undefined') {
				draft[key][nested1] = value;
			} else {
				draft[key] = value;
			}
			return;

		case 'add_to_array':
			const { key: addKey, value: addValue, nested1: addNested1 } = action.payload;

			if (typeof addNested1 !== 'undefined') {
				draft[addKey][addNested1].push(addValue);
			} else {
				draft[addKey].push(addValue);
			}
			return;

		case 'remove_from_array':
			const { key: rmKey, index: rmIndex, nested1: rmNested1 } = action.payload;

			if (typeof rmNested1 !== 'undefined') {
				draft[rmKey][rmNested1].splice(rmIndex, 1);
			} else {
				draft[rmKey].splice(rmIndex, 1);
			}
			return;

		default:
			return;
	}
}

// webhook_types:
// - general
// - slack
// - rocketchat
// - trello
// - asana
// - google chat
// - jira
// - basecamp
// - ms teams
// - salesforce
// - HTML form

// event_type
// - created : send on every feedback item
// - user_init : initialized by user

export default function WebHookAddItDialog({
	webhook = {},
	eventType: eventTypeFromProps,
	surveyId: surveyIdFromProps,
	type: webhookTypeFromProps,
	open,
	onClose = () => {},
	...props
}) {
	const classes = useStyles({ type: webhookTypeFromProps });
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { api } = useWebHookContext();
	const dialogRef = useRef(null);
	const [clicked, setClicked] = useState(false);

	const [state, dispatch] = useImmerReducer(
		reducer,
		webhookBase({
			id: webhook.webhook_id ?? ObjectID().toString(),
			webhook: webhook,
			domain: app.domain,
			project_id: app.projects.current.id,
			webhookType: webhookTypeFromProps ?? null,
			eventType: eventTypeFromProps ?? null,
			surveyId: surveyIdFromProps ?? null,
		})
	);

	const { postForm: saveHook, loading: loadingSave } = useSaveWebhook({
		hook: state,
		onSuccess: response => {
			api.dispatch({
				type: webhook.webhook_id ? 'update_rule' : 'add_filled_rule',
				payload: { webhook: state },
			});
			enqueueSnackbar(
				webhook.webhook_id
					? t('integrations-webhook-addit_dialog-snackbar-webhook_updated', {
							name: state.name,
					  })
					: t('integrations-webhook-addit_dialog-snackbar-webhook_created', {
							name: state.name,
					  })
			);
			if (!webhook.webhook_id) {
				resetConfig();
			}
			onClose({ refreshComponent: true });
		},
	});

	const [activeStep, setActiveStep] = useState(
		webhook.webhook_id && !webhookTypeFromProps ? 2 : webhook.webhook_type ? 1 : 0
	);

	const steps = [
		...(!webhookTypeFromProps
			? [t`integrations-webhook-addit_dialog-stepper-select_type`]
			: []),
		t`integrations-webhook-addit_dialog-stepper-connect_api`,
		...(state.event_type === 'user_init'
			? [t`integrations-webhook-addit_dialog-stepper-enter_name`]
			: [t`integrations-webhook-addit_dialog-stepper-selecteer_data`]),
	];

	const pages = pageMap[webhookTypeFromProps ? 'preselectedWebhookType' : 'default'];
	const webhookType = WEBHOOK_TYPES.find(type => type.value === state.webhook_type);

	function resetConfig() {
		dispatch({
			type: 'reset_config',
			payload: webhookBase({
				id: ObjectID().toString(),
				webhook: {},
				domain: app.domain,
				project_id: app.projects.current.id,
				webhookType: webhookTypeFromProps ?? null,
				eventType: eventTypeFromProps ?? null,
				surveyId: surveyIdFromProps ?? null,
			}),
		});

		setClicked(false);
		setActiveStep(
			webhook.webhook_id && !webhookTypeFromProps ? 2 : webhook.webhook_type ? 1 : 0
		);
	}

	function setValue({ key, nested1, nested2, nested3, value }) {
		dispatch({
			type: 'set_value',
			payload: {
				key,
				value,
				nested1,
				nested2,
				nested3,
			},
		});
	}

	function addToArray({ key, nested1, value }) {
		dispatch({
			type: 'add_to_array',
			payload: {
				key,
				value,
				nested1,
			},
		});
	}

	function removeArrayIndex({ key, nested1, index }) {
		dispatch({
			type: 'remove_from_array',
			payload: {
				key,
				index,
				nested1,
			},
		});
	}

	function setTypeState(setType) {
		switch (setType) {
			case 'asana':
				setValue({ key: 'endpoint', value: [ASANA_ENDPOINT] });
				setValue({ key: 'asana_apicreds', value: [asanaBase()] });
				break;

			case 'trello':
				setValue({ key: 'endpoint', value: [TRELLO_ENDPOINT] });
				setValue({ key: 'trello_apicreds', value: [trelloBase()] });
				break;

			case 'jira':
				setValue({ key: 'endpoint', value: [JIRA_ENDPOINT] });
				setValue({ key: 'api_creds', value: [jiraBase()] });
				break;

			case 'basecamp':
				setValue({ key: 'endpoint', value: [''] });
				setValue({ key: 'api_creds', value: [basecampBase()] });
				break;

			case 'salesforce-form':
				setValue({ key: 'endpoint', value: [SALESFORCE_ENDPOINTS[0]] });
				break;

			default:
				setValue({ key: 'endpoint', value: [''] });
				setValue({ key: 'api_creds', value: [] });
				setValue({ key: 'trello_apicreds', value: [] });
				setValue({ key: 'asana_apicreds', value: [] });
				break;
		}
	}

	function isValid(pages) {
		return pages.every(page => pageValid(page));
	}

	function pageValid(page) {
		switch (page) {
			case 'selectWebhookType':
				return Boolean(state.webhook_type);

			case 'connectApi':
				const endpointValid =
					state.endpoint.filter(point =>
						state.webhook_type === 'slack'
							? point.length > 0
							: ruleTests['urlHttps'](point)
					).length === state.endpoint.length;

				const customHeaderValid =
					state.custom_header.enabled === false ||
					state.custom_header.secret_token !== '';

				const asanaValid =
					state.webhook_type === 'asana'
						? state.asana_apicreds.filter(cred => {
								return cred.workspaceId && cred.projectId;
						  }).length === state.asana_apicreds.length
						: true;

				const trelloValid =
					state.webhook_type === 'trello'
						? state.trello_apicreds.filter(cred => {
								return cred.idBoard && cred.idList;
						  }).length === state.trello_apicreds.length
						: true;

				const jiraValid =
					state.webhook_type === 'jira'
						? state.api_creds.filter(cred => {
								return cred?.projectKey && cred?.issueType;
						  }).length === state.api_creds.length
						: true;

				const basecampValid =
					state.webhook_type === 'basecamp'
						? state.api_creds.filter(cred => {
								return cred?.project_id && cred?.todo_list_id;
						  }).length === state.api_creds.length
						: true;

				const salesforceValid =
					state.webhook_type === 'salesforce-form' ? isValidOrgId(state.org_id) : true;

				return (
					endpointValid &&
					customHeaderValid &&
					asanaValid &&
					trelloValid &&
					jiraValid &&
					basecampValid &&
					salesforceValid
				);

			case 'dataSelection':
				const emailValid =
					state.webhook_type === 'salesforce-form' && state.sf_form === 'web-to-lead'
						? state.extra_fields.find(list => list.key.match(/email/gi) !== null)
						: true;

				return emailValid && state.name && (state.survey_id || state.all_project_data);

			default:
				return false;
		}
	}

	return (
		<SimpleDialog
			PaperProps={{ ref: dialogRef }}
			title={
				<>
					{webhook.webhook_id
						? t(`integrations-webhook-addit_dialog-title-edit_${webhookType.value ?? ''}`)
						: state.webhook_type && pages[activeStep] !== 'selectWebhookType'
						? t(`integrations-webhook-addit_dialog-title-new_${webhookType.value ?? ''}`)
						: t`integrations-webhook-addit_dialog-title-new`}
					<Stepper
						activeStep={activeStep}
						nonLinear
						classes={{ root: classes.stepper }}
					>
						{steps.map((label, index) => {
							return (
								<Step key={label}>
									<StepButton
										completed={Boolean(index !== activeStep && pageValid(pages[index]))}
										onClick={() => setActiveStep(index)}
									>
										<StepLabel error={clicked && !pageValid(pages[index])}>
											{label}
										</StepLabel>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</>
			}
			submit={
				pages[activeStep] !== 'dataSelection'
					? t`integrations-webhook-addit_dialog-button_submit-next`
					: webhook.webhook_id
					? t`integrations-webhook-addit_dialog-button_submit-edit`
					: t`integrations-webhook-addit_dialog-button_submit-add`
			}
			open={open}
			onClose={onClose}
			maxWidth="sm"
			loading={loadingSave}
			dataTestElement={
				pages[activeStep] !== 'dataSelection'
					? 'apiNewNext'
					: webhook.webhook_id
					? 'apiEditWebhook'
					: 'apiCreateWebhook'
			}
			dataTrackEvent={
				webhook.webhook_id
					? 'integrations_webhook_edited'
					: 'integrations_webhook_created'
			}
			onSubmit={() => {
				if (pages[activeStep] === 'dataSelection') {
					setClicked(true);
					if (isValid(pages)) {
						saveHook();
					}
				} else {
					setActiveStep(step => step + 1);
				}
			}}
			{...props}
		>
			{pages[activeStep] === 'selectWebhookType' && (
				<SelectWebhookStep
					state={state}
					webhookType={webhookType}
					setValue={setValue}
					setTypeState={setTypeState}
				/>
			)}

			{pages[activeStep] === 'connectApi' && (
				<>
					{state.webhook_type === 'jira' && (
						<ConnectJiraStep
							state={state}
							clicked={clicked}
							setValue={setValue}
							addToArray={addToArray}
							removeArrayIndex={removeArrayIndex}
							dialogRef={dialogRef}
						/>
					)}

					{state.webhook_type === 'basecamp' && (
						<ConnectBaseCampStep
							state={state}
							clicked={clicked}
							setValue={setValue}
							addToArray={addToArray}
							removeArrayIndex={removeArrayIndex}
							dialogRef={dialogRef}
						/>
					)}

					{[
						'general',
						'slack',
						'rocketchat',
						'googlechat',
						'ms_teams',
						'ms_teams_workflow',
					].includes(state.webhook_type) && (
						<ConnectDefaultHookStep
							state={state}
							clicked={clicked}
							setValue={setValue}
							addToArray={addToArray}
							removeArrayIndex={removeArrayIndex}
						/>
					)}

					{state.webhook_type === 'trello' && (
						<ConnectTrelloStep
							state={state}
							clicked={clicked}
							setValue={setValue}
							addToArray={addToArray}
						/>
					)}

					{state.webhook_type === 'asana' && (
						<ConnectAsanaStep
							state={state}
							clicked={clicked}
							setValue={setValue}
							addToArray={addToArray}
						/>
					)}

					{state.webhook_type === 'salesforce-form' && (
						<ConnectSalesForceStep
							state={state}
							clicked={clicked}
							setValue={setValue}
						/>
					)}

					{state.webhook_type === 'html-form' && (
						<ConnectHtmlFormStep
							state={state}
							clicked={clicked}
							setValue={setValue}
						/>
					)}
				</>
			)}
			{pages[activeStep] === 'dataSelection' && (
				<>
					{state.event_type === 'created' && (
						<DataSelectionStep
							state={state}
							clicked={clicked}
							surveyIdFromProps={surveyIdFromProps}
							pageValid={pageValid(pages[activeStep])}
							setValue={setValue}
							addToArray={addToArray}
							removeArrayIndex={removeArrayIndex}
						/>
					)}

					{state.event_type === 'user_init' && (
						<DataUserInitStep
							state={state}
							setValue={setValue}
						/>
					)}
				</>
			)}
		</SimpleDialog>
	);
}

function SelectWebhookStep({
	state,
	webhookType,
	setValue = () => {},
	setTypeState = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	return (
		<>
			<Box className={classes.blockWrapper}>
				<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_select-title`}</Typography>
				<Typography
					color="textSecondary"
					mb={2}
				>
					{t`integrations-webhook-addit_dialog-page_select-subtitle`}
				</Typography>
				<Grid
					container
					spacing={2}
					direction="row"
				>
					{WEBHOOK_TYPES.filter(
						type =>
							type.event_types.includes(state.event_type) && type.value !== 'ms_teams'
					).map(type => {
						return (
							<Grid
								item
								xs={4}
								key={type.value}
							>
								<SelectionCard
									selected={
										type.value === state.webhook_type ||
										(state.webhook_type === 'ms_teams' &&
											type.value === 'ms_teams_workflow')
									}
									disabled={
										type.value === 'ms_teams_workflow' &&
										state.webhook_type === 'ms_teams'
									}
									onClick={() => {
										setValue({ key: 'webhook_type', value: type.value });
										setTypeState(type.value);

										if (['salesforce-form', 'html-form'].includes(type.value)) {
											setValue({ key: 'extra_fields', value: [htmlFormBase()] });
											setValue({ key: 'prefilled_fields', value: [htmlFormBase()] });
										}
										if (type.value !== 'general') {
											setValue({ key: 'custom_header', value: customHeaderBase() });
										}
									}}
									outerContent={
										<Box
											p={2}
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<Box
												clone
												maxWidth="100%"
												maxHeight={40}
											>
												<img src={type.img} />
											</Box>
											{type.value === 'ms_teams_workflow' &&
												state.webhook_type === 'ms_teams' && (
													<Chip
														label={t`integrations-webhook-addit_dialog-alert_ms_teams-dialog_chip`}
														size="small"
														className={classes.legacyChip}
													/>
												)}
										</Box>
									}
								/>
							</Grid>
						);
					})}
					{state.webhook_type === 'ms_teams' && (
						<Grid
							item
							xs={12}
						>
							<Alert severity="info">
								<Typography variant="body2">
									<Trans
										i18nKey="integrations-webhook-addit_dialog-alert_ms_teams"
										components={{
											kbLink: (
												<KnowledgeBaseLink
													page="ms_teams_setup"
													color="inherit"
													verticalAlign="inherit"
												/>
											),
										}}
									/>
								</Typography>
							</Alert>
						</Grid>
					)}
				</Grid>
			</Box>
			<Box
				className={classes.blockWrapper}
				mt={3}
			>
				<FormGroup>
					<FormLabel>{t`integrations-webhook-addit_dialog-page_select-run_integration`}</FormLabel>
					<RadioGroup
						value={state.event_type}
						onChange={e => {
							setValue({ key: 'event_type', value: e.target.value });
							setValue({ key: 'survey_id', value: null });
							if (e.target.value === 'user_init') {
								setValue({ key: 'all_project_data', value: true });
								setValue({ key: 'active', value: true });

								if (['salesforce-form', 'html-form'].includes(state.webhook_type)) {
									setValue({ key: 'webhook_type', value: 'general' });
									enqueueSnackbar(
										t(
											'integrations-webhook-addit_dialog-page_select-snackbar_webhooktype_warning',
											{ type: webhookType.label }
										)
									);
								}
							} else {
								setValue({ key: 'all_project_data', value: false });
							}
						}}
					>
						<FormControlLabel
							value="created"
							control={<Radio />}
							label={t`integrations-webhook-addit_dialog-page_select-integration_option-created`}
						/>
						<FormControlLabel
							value="user_init"
							control={<Radio />}
							label={t`integrations-webhook-addit_dialog-page_select-integration_option-user_init`}
						/>
					</RadioGroup>
				</FormGroup>
			</Box>
		</>
	);
}

function ConnectDefaultHookStep({
	state,
	clicked,
	setValue = () => {},
	addToArray = () => {},
	removeArrayIndex = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-default-title`}</Typography>
			<Typography
				color="textSecondary"
				gutterBottom
			>
				{t`integrations-webhook-addit_dialog-page_connect-default-subtitle`}
			</Typography>
			{state.endpoint.map((endpointPath, endpointIndex) => {
				return (
					<Grid
						container
						key={'endpoint' + endpointIndex}
					>
						<Grid
							item
							xs
						>
							<ValidatedField
								fullWidth
								value={endpointPath.replace(SLACK_ENDPOINT, '')}
								InputProps={{
									...(state.webhook_type === 'slack' && {
										startAdornment: (
											<InputAdornment position="start">{SLACK_ENDPOINT}</InputAdornment>
										),
									}),
								}}
								placeholder={t(
									`integrations-webhook-addit_dialog-page_connect-default-endpoint_placeholder-${
										state.webhook_type === 'slack' ? 'slack' : 'default'
									}`
								)}
								onChange={e => {
									const endpoint = `${
										state.webhook_type === 'slack' ? SLACK_ENDPOINT : ''
									}${e.currentTarget.value}`;
									setValue({
										key: 'endpoint',
										nested1: endpointIndex,
										value: endpoint,
									});
								}}
								rules={
									state.webhook_type === 'slack' ? { required: true } : { urlHttps: true }
								}
								errorMessage={t(
									`integrations-webhook-addit_dialog-page_connect-default-endpoint_errormessage-${
										state.webhook_type === 'slack' ? 'slack' : 'default'
									}`
								)}
								showErrors={clicked}
								dataTestElement="apiNewEndpoint"
							/>
						</Grid>
						<Grid item>
							<ActionIconButton
								tooltip={t`integrations-webhook-addit_dialog-page_connect-default-endpoint_button-remove_endpoint`}
								action="remove_list"
								onClick={() => {
									if (state.endpoint.length === 1) {
										setValue({
											key: 'endpoint',
											nested1: 0,
											value: '',
										});
									} else {
										removeArrayIndex({
											key: 'endpoint',
											index: endpointIndex,
										});
									}
								}}
							/>
						</Grid>
					</Grid>
				);
			})}

			<ActionButton
				action="add_list"
				label={t`integrations-webhook-addit_dialog-page_connect-default-endpoint_button-add`}
				onClick={() =>
					addToArray({
						key: 'endpoint',
						value: '',
					})
				}
			/>

			{state.webhook_type === 'general' && (
				<>
					<Box mt={2}>
						<FormControlLabel
							control={<Checkbox />}
							checked={state.custom_header.enabled}
							label={
								<Box>
									<Typography>
										<Trans
											i18nKey="integrations-webhook-addit_dialog-page_connect-json_hook-checkbox-title"
											components={{
												code: <code />,
											}}
										/>
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										<Trans i18nKey="integrations-webhook-addit_dialog-page_connect-json_hook-checkbox-subtitle" />
									</Typography>
								</Box>
							}
							onChange={e => {
								setValue({
									key: 'custom_header',
									nested1: 'enabled',
									value: e.target.checked,
								});

								if (!e.target.checked) {
									setValue({
										key: 'custom_header',
										nested1: 'secret_token',
										value: '',
									});
								}
							}}
						/>
						{state.custom_header.enabled && (
							<Box mt={1}>
								<ValidatedField
									fullWidth
									label={t`integrations-webhook-addit_dialog-page_connect-json_hook-checkbox-label_secret`}
									value={state.custom_header.secret_token}
									onChange={e =>
										setValue({
											key: 'custom_header',
											nested1: 'secret_token',
											value: e.currentTarget.value,
										})
									}
									rules={{ required: true }}
									showErrors={clicked}
									errorMessage={t`integrations-webhook-addit_dialog-page_connect-custom_header_errormessage-token`}
								/>
							</Box>
						)}
					</Box>
					<Box mt={1}>
						<KnowledgeBaseLink page="webhook_custom_header">
							{t`integrations-webhook-addit_dialog-page_connect-custom_header_explainer_link`}
						</KnowledgeBaseLink>
					</Box>
				</>
			)}

			{['ms_teams', 'ms_teams_workflow'].includes(state.webhook_type) && (
				<>
					<Box mt={2}>
						<FormControlLabel
							control={<Checkbox />}
							checked={state.webhook_type === 'ms_teams'}
							label={
								<Box>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										{t`integrations-webhook-addit_dialog-page_connect-deprecated_connector`}
									</Typography>
								</Box>
							}
							onChange={e => {
								setValue({
									key: 'webhook_type',
									value: e.target.checked ? 'ms_teams' : 'ms_teams_workflow',
								});
							}}
						/>
						{state.custom_header.enabled && (
							<Box mt={1}>
								<ValidatedField
									fullWidth
									label={t`integrations-webhook-addit_dialog-page_connect-json_hook-checkbox-label_secret`}
									value={state.custom_header.secret_token}
									onChange={e =>
										setValue({
											key: 'custom_header',
											nested1: 'secret_token',
											value: e.currentTarget.value,
										})
									}
									rules={{ required: true }}
									showErrors={clicked}
									errorMessage={t`integrations-webhook-addit_dialog-page_connect-custom_header_errormessage-token`}
								/>
							</Box>
						)}
					</Box>
				</>
			)}

			{state.webhook_type === 'ms_teams' && (
				<Grid
					item
					xs={12}
				>
					<Alert severity="info">
						<Typography variant="body2">
							<Trans
								i18nKey="integrations-webhook-addit_dialog-alert_ms_teams"
								components={{
									kbLink: (
										<KnowledgeBaseLink
											page="ms_teams_setup"
											color="inherit"
											verticalAlign="inherit"
										/>
									),
								}}
							/>
						</Typography>
					</Alert>
				</Grid>
			)}
		</Box>
	);
}

function ConnectJiraStep({
	state,
	dialogRef,
	setValue = () => {},
	addToArray = () => {},
	removeArrayIndex = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-jira-title`}</Typography>
			<Typography
				color="textSecondary"
				mb={1}
			>
				{t`integrations-webhook-addit_dialog-page_connect-jira-subtitle`}
			</Typography>

			<AuthList
				type={state.webhook_type}
				selectedId={state.api_creds?.[0]?.auth_id}
				onSelect={auth => {
					state.api_creds.forEach((cred, index) => {
						setValue({
							key: 'api_creds',
							nested1: index,
							nested2: 'auth_id',
							value: auth.id,
						});
						setValue({
							key: 'api_creds',
							nested1: index,
							nested2: 'userId',
							value: auth.userId,
						});
					});
				}}
				target={dialogRef}
			/>

			{state.api_creds?.[0]?.auth_id && (
				<Box mt={2}>
					<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-jira-endpoint_title`}</Typography>
					<Typography
						color="textSecondary"
						mb={1}
					>{t`integrations-webhook-addit_dialog-page_connect-jira-endpoint_subtitle`}</Typography>

					{state.api_creds.map((cred, index) => {
						return (
							<JiraRow
								key={'jira' + index}
								index={index}
								{...cred}
								onChangeProject={e =>
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'projectKey',
										value: e.target.value,
									})
								}
								onChangeIssue={e =>
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'issueType',
										value: e.target.value,
									})
								}
								onChangeParentId={e =>
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'issueType',
										value: e.currentTarget.value,
									})
								}
								onChangeLabel={labels =>
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'labels',
										value: labels.map(label => label.label),
									})
								}
								onRemoveRow={() => {
									if (index !== 0) {
										removeArrayIndex({
											key: 'endpoint',
											index: index,
										});
										removeArrayIndex({
											key: 'api_creds',
											index: index,
										});
									} else {
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'projectKey',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'issueType',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'parent_id',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'labels',
											value: [],
										});
									}
								}}
							/>
						);
					})}

					<Box mt={1}>
						<ActionButton
							action="add_list"
							onClick={() => {
								addToArray({
									key: 'api_creds',
									value: jiraBase(state.api_creds?.[0]?.auth_id),
								});
								addToArray({
									key: 'endpoint',
									value: JIRA_ENDPOINT,
								});
							}}
						>
							{t`integrations-webhook-addit_dialog-page_connect-jira-endpoint_button-endpoint`}
						</ActionButton>
					</Box>
				</Box>
			)}
		</Box>
	);
}

function ConnectBaseCampStep({
	state,
	dialogRef,
	setValue = () => {},
	addToArray = () => {},
	removeArrayIndex = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-basecamp-title`}</Typography>
			<Typography
				color="textSecondary"
				mb={1}
			>
				{t`integrations-webhook-addit_dialog-page_connect-basecamp-subtitle`}
			</Typography>

			<AuthList
				type={state.webhook_type}
				selectedId={state.api_creds?.[0]?.auth_id}
				onSelect={auth => {
					state.api_creds.forEach((cred, index) => {
						setValue({
							key: 'api_creds',
							nested1: index,
							nested2: 'auth_id',
							value: auth.id,
						});
						setValue({
							key: 'api_creds',
							nested1: index,
							nested2: 'userId',
							value: auth.userId,
						});
					});
				}}
				target={dialogRef}
			/>

			{state.api_creds?.[0]?.auth_id && (
				<Box mt={2}>
					<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-basecamp-endpoint_title`}</Typography>
					<Typography
						color="textSecondary"
						mb={1}
					>{t`integrations-webhook-addit_dialog-page_connect-basecamp-endpoint_subtitle`}</Typography>

					{state.api_creds.map((cred, index) => {
						return (
							<BasecampRow
								key={'basecamp' + index}
								index={index}
								{...cred}
								endpoint={state.endpoint[index]}
								onChangeProject={({
									project_id,
									todo_set_id,
									endpoint,
									project_name,
								}) => {
									setValue({
										key: 'endpoint',
										nested1: index,
										value: endpoint,
									});
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'project_id',
										value: project_id,
									});
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'project_name',
										value: project_name,
									});
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'todo_set_id',
										value: todo_set_id,
									});
								}}
								onChangeTodoList={todo => {
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'todo_list_id',
										value: todo.id,
									});
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'todo_list_name',
										value: todo.name,
									});
								}}
								onChangeAssignee={user => {
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'assignee',
										value: user?.id ?? '',
									});
									setValue({
										key: 'api_creds',
										nested1: index,
										nested2: 'assignee_name',
										value: user?.name ?? '',
									});
								}}
								onRemoveRow={() => {
									if (index !== 0) {
										removeArrayIndex({
											key: 'endpoint',
											index: index,
										});
										removeArrayIndex({
											key: 'api_creds',
											index: index,
										});
									} else {
										setValue({
											key: 'endpoint',
											nested1: index,
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'project_id',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'project_name',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'todo_set_id',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'todo_list_id',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'todo_list_name',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'assignee',
											value: '',
										});
										setValue({
											key: 'api_creds',
											nested1: index,
											nested2: 'assignee_name',
											value: '',
										});
									}
								}}
							/>
						);
					})}

					<Box mt={1}>
						<ActionButton
							action="add_list"
							onClick={() => {
								addToArray({
									key: 'api_creds',
									value: basecampBase(state.api_creds?.[0]?.auth_id),
								});
								addToArray({
									key: 'endpoint',
									value: '',
								});
							}}
						>
							{t`integrations-webhook-addit_dialog-page_connect-basecamp-endpoint_button-add`}
						</ActionButton>
					</Box>
				</Box>
			)}
		</Box>
	);
}

function ConnectTrelloStep({
	state,
	clicked,
	setValue = () => {},
	addToArray = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const connectionEstablished =
		state.trello_apicreds?.[0]?.username &&
		state.trello_apicreds?.[0]?.key &&
		state.trello_apicreds?.[0]?.token;

	return (
		<Box className={classes.blockWrapper}>
			{connectionEstablished && (
				<Alert>{t`integrations-webhook-addit_dialog-page_connect-trello-alert-successful_connection`}</Alert>
			)}
			{!connectionEstablished && (
				<>
					<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-trello-title`}</Typography>
					<Typography variant="body1">
						<Trans
							i18nKey={
								'integrations-webhook-addit_dialog-page_connect-trello-connection_list'
							}
							components={{
								ol: <ol />,
								li: <li />,
								link1: (
									<Link
										href="https://trello.com/your/account"
										target="_blank"
									/>
								),
								link2: (
									<Link
										href="https://trello.com/app-key"
										target="_blank"
									/>
								),
							}}
						/>
					</Typography>
				</>
			)}

			{state.trello_apicreds?.map((trelloCred, trelloCredIndex) => {
				return (
					<div key={'trello' + trelloCredIndex}>
						{!trelloCred.token && (
							<>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={6}
									>
										<ValidatedField
											fullWidth
											label={t`integrations-webhook-addit_dialog-page_connect-trello-input_name`}
											value={trelloCred.username}
											onChange={e =>
												setValue({
													key: 'trello_apicreds',
													nested1: trelloCredIndex,
													nested2: 'username',
													value: e.currentTarget.value,
												})
											}
											InputProps={{
												startAdornment: <InputAdornment>@</InputAdornment>,
											}}
											errorMessage={t`integrations-webhook-addit_dialog-page_connect-trello-input_name-errormessage`}
											rules={{ required: true }}
											showErrors={clicked}
										/>
									</Grid>
									<Grid
										item
										xs={6}
									>
										<ValidatedField
											fullWidth
											label={t`integrations-webhook-addit_dialog-page_connect-trello-input_api_key`}
											value={trelloCred.key}
											onChange={e =>
												setValue({
													key: 'trello_apicreds',
													nested1: trelloCredIndex,
													nested2: 'key',
													value: e.currentTarget.value,
												})
											}
											errorMessage={t`integrations-webhook-addit_dialog-page_connect-trello-input_api_key-errormessage`}
											rules={{ required: true }}
											showErrors={clicked}
										/>
									</Grid>
								</Grid>
								<Box mt={1}>
									<ActionButton
										action="connect"
										label={t`integrations-webhook-addit_dialog-page_connect-trello-button_connect`}
										onClick={() => {
											if (trelloCred.username && trelloCred.key) {
												window.open(
													`https://trello.com/1/authorize?expiration=never&name=MopinionWebhook&scope=read,write&response_type=token&key=${trelloCred.key}`
												);
											}
										}}
									/>
								</Box>
							</>
						)}

						{trelloCred.username && trelloCred.key && !trelloCred.token && (
							<ValidatedField
								label={t`integrations-webhook-addit_dialog-page_connect-trello-input_api_token`}
								fullWidth
								value={trelloCred.token}
								onChange={e =>
									setValue({
										key: 'trello_apicreds',
										nested1: trelloCredIndex,
										nested2: 'token',
										value: e.currentTarget.value,
									})
								}
								errorMessage={t`integrations-webhook-addit_dialog-page_connect-trello-input_api_token-errormessage`}
								rules={{ required: true }}
								showErrors={clicked}
							/>
						)}

						{trelloCred.token && (
							<TrelloFlow
								clicked={clicked}
								{...trelloCred}
								setBoard={(id, name) => {
									setValue({
										key: 'trello_apicreds',
										nested1: trelloCredIndex,
										nested2: 'idBoard',
										value: id,
									});
									setValue({
										key: 'trello_apicreds',
										nested1: trelloCredIndex,
										nested2: 'nameBoard',
										value: name,
									});
								}}
								setList={(id, name) => {
									setValue({
										key: 'trello_apicreds',
										nested1: trelloCredIndex,
										nested2: 'idList',
										value: id,
									});
									setValue({
										key: 'trello_apicreds',
										nested1: trelloCredIndex,
										nested2: 'nameList',
										value: name,
									});
								}}
								apiKey={trelloCred.key}
							/>
						)}
					</div>
				);
			})}
			{connectionEstablished && (
				<ActionButton
					action="add_list"
					label={t`integrations-webhook-addit_dialog-page_connect-trello-button-add_list`}
					onClick={() => {
						addToArray({
							key: 'endpoint',
							value: TRELLO_ENDPOINT,
						});
						addToArray({
							key: 'trello_apicreds',
							value: trelloBase({
								username: state.trello_apicreds?.[0]?.username ?? '',
								key: state.trello_apicreds?.[0]?.key ?? '',
								token: state.trello_apicreds?.[0]?.token ?? '',
							}),
						});
					}}
				/>
			)}
		</Box>
	);
}

function ConnectAsanaStep({
	state,
	clicked,
	setValue = () => {},
	addToArray = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const connectionEstablished =
		state.asana_apicreds?.[0]?.refreshToken && state.asana_apicreds?.[0]?.apiKey;

	return (
		<Box className={classes.blockWrapper}>
			{connectionEstablished && (
				<Alert>{t`integrations-webhook-addit_dialog-page_connect-asana-alert-successful_connection`}</Alert>
			)}
			{!connectionEstablished && (
				<>
					<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-asana-title`}</Typography>
					<Typography variant="body1">
						<Trans
							i18nKey={
								'integrations-webhook-addit_dialog-page_connect-asana-connection_list'
							}
							components={{
								ol: <ol />,
								li: <li />,
							}}
						/>
					</Typography>
				</>
			)}

			{state.asana_apicreds.map((asanaCred, asanaCredIndex) => {
				return (
					<div key={'asana' + asanaCredIndex}>
						{!asanaCred.refreshToken && (
							<>
								<ActionButton
									color="primary"
									variant="contained"
									action="link"
									label={t`integrations-webhook-addit_dialog-page_connect-asana-button-get_token`}
									onClick={() => {
										window.open(ASANA_API_TOKEN_URL);
									}}
								/>
								<ValidatedField
									label={t`integrations-webhook-addit_dialog-page_connect-asana-input_api_token`}
									fullWidth
									value={asanaCred.apiKey}
									onChange={e =>
										setValue({
											key: 'asana_apicreds',
											nested1: asanaCredIndex,
											nested2: 'apiKey',
											value: e.currentTarget.value,
										})
									}
									errorMessage={t`integrations-webhook-addit_dialog-page_connect-asana-input_api_token-errormessage`}
									rules={{ required: true }}
									showErrors={clicked}
								/>
							</>
						)}
						{asanaCred.apiKey && (
							<AsanaFlow
								clicked={clicked}
								{...asanaCred}
								setRefreshToken={token =>
									setValue({
										key: 'asana_apicreds',
										nested1: asanaCredIndex,
										nested2: 'refreshToken',
										value: token,
									})
								}
								setWorkspace={(id, name) => {
									setValue({
										key: 'asana_apicreds',
										nested1: asanaCredIndex,
										nested2: 'workspaceId',
										value: id,
									});
									setValue({
										key: 'asana_apicreds',
										nested1: asanaCredIndex,
										nested2: 'workspaceName',
										value: name,
									});
								}}
								setProject={(id, name) => {
									setValue({
										key: 'asana_apicreds',
										nested1: asanaCredIndex,
										nested2: 'projectId',
										value: id,
									});
									setValue({
										key: 'asana_apicreds',
										nested1: asanaCredIndex,
										nested2: 'projectName',
										value: name,
									});
								}}
							/>
						)}
					</div>
				);
			})}

			{connectionEstablished && (
				<ActionButton
					action="add_list"
					label={t`integrations-webhook-addit_dialog-page_connect-asana-button-add_board`}
					onClick={() => {
						addToArray({
							key: 'endpoint',
							value: ASANA_ENDPOINT,
						});
						addToArray({
							key: 'asana_apicreds',
							value: asanaBase({
								refreshToken: state.asana_apicreds?.[0].refreshToken ?? '',
								apiKey: state.asana_apicreds?.[0]?.apiKey ?? '',
							}),
						});
					}}
				/>
			)}
		</Box>
	);
}

function ConnectSalesForceStep({ state, clicked, setValue = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-salesforce-title`}</Typography>
			<Typography
				variant="body1"
				color="textSecondary"
				mb={2}
			>
				{t`integrations-webhook-addit_dialog-page_connect-salesforce-subtitle`}
			</Typography>

			<Box mb={2}>
				<FormGroup>
					<FormLabel>{t`integrations-webhook-addit_dialog-page_connect-salesforce-form_type-title`}</FormLabel>
					<RadioGroup
						aria-label="Salesforce formtype"
						name="sf-form"
						value={state.sf_form}
						onChange={e => {
							setValue({
								key: 'sf_form',
								value: e.target.value,
							});
						}}
						row
					>
						<FormControlLabel
							value="web-to-case"
							control={<Radio />}
							label="Web-to-Case"
							onClick={() => {
								setValue({
									key: 'endpoint',
									value: [SALESFORCE_ENDPOINTS[0]],
								});
								setValue({
									key: 'sf_form',
									value: 'web-to-case',
								});
							}}
						/>
						<FormControlLabel
							value="web-to-lead"
							control={<Radio />}
							label="Web-to-Lead"
							onClick={() => {
								setValue({
									key: 'endpoint',
									value: [SALESFORCE_ENDPOINTS[1]],
								});
								setValue({
									key: 'sf_form',
									value: 'web-to-lead',
								});
							}}
						/>
					</RadioGroup>
				</FormGroup>
			</Box>

			<Box mb={2}>
				<ValidatedField
					fullWidth
					value={state.endpoint[0]}
					placeholder={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_url_placeholder`}
					onChange={e => {
						setValue({
							key: 'endpoint',
							value: [e.currentTarget.value],
						});
					}}
					rules={[['urlHttps', true]]}
					errorMessage={`integrations-webhook-addit_dialog-page_connect-salesforce-input_url_errormessage`}
					showErrors={clicked}
					dataTestElement="apiNewEndpoint"
					label={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_url_label`}
				/>
			</Box>

			<Box mb={2}>
				<ValidatedField
					fullWidth
					label={t(
						'integrations-webhook-addit_dialog-page_connect-salesforce-input_org_id_label',
						{
							orgid: state.sf_form === 'web-to-case' ? 'orgid' : 'oid',
						}
					)}
					value={state.org_id}
					placeholder={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_org_id_placeholder`}
					onChange={e => {
						setValue({
							key: 'org_id',
							value: e.currentTarget.value,
						});
					}}
					rules={[['custom', v => isValidOrgId(v)]]}
					errorMessage={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_org_id_errormessage`}
					showErrors={clicked}
				/>
			</Box>

			<Box mb={2}>
				<TextField
					fullWidth
					label={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_datapoint_label`}
					value={state.feedback_field}
					placeholder={t`integrations-webhook-addit_dialog-page_connect-salesforce-input_datapoint_placeholder`}
					onChange={e => {
						setValue({
							key: 'feedback_field',
							value: e.currentTarget.value,
						});
					}}
				/>
			</Box>
		</Box>
	);
}

function ConnectHtmlFormStep({ state, clicked, setValue = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_connect-html-title`}</Typography>

			<Box mb={2}>
				<ValidatedField
					fullWidth
					value={state.endpoint[0]}
					placeholder={t`integrations-webhook-addit_dialog-page_connect-html-input_url_placeholder`}
					onChange={e => {
						setValue({
							key: 'endpoint',
							value: [e.currentTarget.value],
						});
					}}
					rules={[['urlHttps', true]]}
					errorMessage={t`integrations-webhook-addit_dialog-page_connect-html-input_url_errormessage`}
					showErrors={clicked}
					dataTestElement="apiNewEndpoint"
					label={t`integrations-webhook-addit_dialog-page_connect-html-input_url_label`}
				/>
			</Box>
		</Box>
	);
}

function DataSelectionStep({
	state,
	surveyIdFromProps,
	clicked,
	pageValid,
	setValue = () => {},
	addToArray = () => {},
	removeArrayIndex = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<Typography variant="subtitle">{t`integrations-webhook-addit_dialog-page_data_created-title`}</Typography>
			<Typography
				color="textSecondary"
				gutterBottom
			>
				{t`integrations-webhook-addit_dialog-page_data_created-subtitle`}
			</Typography>
			{!surveyIdFromProps && (
				<Box mb={3}>
					<ValidatedField
						component={DataSourceSelect}
						label={t`integrations-webhook-addit_dialog-page_data_created-input_select_source-label`}
						value={
							state.survey_id ? state.survey_id : state.all_project_data ? 'ALL_DATA' : ''
						}
						withAllData={
							['salesforce-form', 'html-form'].indexOf(state.webhook_type) === -1
						}
						onChange={e => {
							const { value } = e.target;

							if (value === 'ALL_DATA') {
								setValue({
									key: 'survey_id',
									value: null,
								});
								setValue({
									key: 'all_project_data',
									value: true,
								});
							} else {
								setValue({
									key: 'survey_id',
									value: e.target.value,
								});
								setValue({
									key: 'all_project_data',
									value: false,
								});
							}
						}}
						validateValue={state.survey_id || state.all_project_data}
						errorMessage={t`integrations-webhook-addit_dialog-page_data_created-input_select_source-placeholder`}
						rules={{ required: true }}
						showErrors={clicked}
					/>
				</Box>
			)}

			{state.survey_id && ['salesforce-form', 'html-form'].includes(state.webhook_type) && (
				<>
					<Box mb={3}>
						<FormLabel>
							{t(
								`integrations-webhook-addit_dialog-page_data_created-${state.webhook_type}-input_datafields_label`
							)}
						</FormLabel>
						{state.extra_fields?.map((param, paramIndex) => {
							return (
								<Grid
									container
									key={'param' + paramIndex}
									spacing={2}
									alignItems="center"
								>
									<Grid
										item
										xs={6}
									>
										<DataFieldSelect
											value={param.value}
											renderValueTextOnly
											sourceId={state.survey_id}
											onChange={e => {
												setValue({
													key: 'extra_fields',
													nested1: paramIndex,
													nested2: 'value',
													value: e.target.value,
												});
											}}
										/>
									</Grid>
									<Grid
										item
										xs={6}
									>
										<TextField
											fullWidth
											value={param.key}
											placeholder={t`integrations-webhook-addit_dialog-page_data_created-input_name_placeholder`}
											onChange={e => {
												setValue({
													key: 'extra_fields',
													nested1: paramIndex,
													nested2: 'key',
													value: e.currentTarget.value,
												});
											}}
										/>
									</Grid>
								</Grid>
							);
						})}
						{state.webhook_type === 'salesforce-form' && state.sf_form === 'web-to-lead' && (
							<Typography
								display="block"
								variant="caption"
								color="textSecondary"
								className={clicked && !pageValid ? classes.error : ''}
								gutterBottom
							>
								{t`integrations-webhook-addit_dialog-page_data_created-salesforce-form-input_datafields_helper`}
							</Typography>
						)}
						<ActionButton
							action="add_list"
							onClick={() =>
								addToArray({
									key: 'extra_fields',
									value: htmlFormBase(),
								})
							}
							label={t`integrations-webhook-addit_dialog-page_data_created-datafields_button_add_field`}
						/>
					</Box>

					<Box mb={3}>
						<FormLabel>
							{t(
								`integrations-webhook-addit_dialog-page_data_created-${state.webhook_type}-input_custom_values_label`
							)}
						</FormLabel>
						{state.prefilled_fields?.map((param, paramIndex) => {
							return (
								<Grid
									container
									key={'param' + paramIndex}
									spacing={2}
									alignItems="center"
								>
									<Grid
										item
										xs={6}
									>
										<TextField
											value={param.value}
											placeholder={t`integrations-webhook-addit_dialog-page_data_created-input_custom_values_value_placeholder`}
											fullWidth
											onChange={e =>
												setValue({
													key: 'prefilled_fields',
													nested1: paramIndex,
													nested2: 'value',
													value: e.currentTarget.value,
												})
											}
										/>
									</Grid>
									<Grid
										item
										xs={6}
									>
										<TextField
											value={param.key}
											placeholder={t`integrations-webhook-addit_dialog-page_data_created-input_custom_values_name_placeholder`}
											fullWidth
											onChange={e =>
												setValue({
													key: 'prefilled_fields',
													nested1: paramIndex,
													nested2: 'key',
													value: e.currentTarget.value,
												})
											}
										/>
									</Grid>
								</Grid>
							);
						})}
						<Typography
							display="block"
							variant="caption"
							color="textSecondary"
							gutterBottom
						>
							{t`integrations-webhook-addit_dialog-page_data_created-input_custom_values_value_helper`}
						</Typography>
						<ActionButton
							action="add_list"
							onClick={() =>
								addToArray({ key: 'prefilled_fields', value: htmlFormBase() })
							}
							label={t`integrations-webhook-addit_dialog-page_data_created-custom_values_button_add_field`}
						/>
					</Box>
				</>
			)}

			{!state.all_project_data &&
				state.survey_id &&
				!['salesforce-form', 'html-form'].includes(state.webhook_type) && (
					<Box mb={3}>
						<FormGroup>
							<FormLabel>{t`integrations-webhook-addit_dialog-page_data_created-send_data_radio-label`}</FormLabel>
							<RadioGroup
								//row
								value={
									state.parameters.all && state.parameters.aliasses?.length === 0
										? 'all'
										: state.parameters.all && state.parameters.aliasses?.length > 0
										? 'aliasses'
										: 'only_datafields'
								}
								onChange={e => {
									const { value } = e.target;

									switch (value) {
										case 'all':
											setValue({
												key: 'parameters',
												nested1: 'all',
												value: true,
											});
											setValue({
												key: 'parameters',
												nested1: 'aliasses',
												value: [],
											});
											return;
										case 'aliasses':
											setValue({
												key: 'parameters',
												nested1: 'all',
												value: true,
											});
											if (state.parameters.aliasses?.length === 0) {
												setValue({
													key: 'parameters',
													nested1: 'aliasses',
													value: [paramBase()],
												});
											}
											return;
										case 'only_datafields':
											setValue({
												key: 'parameters',
												nested1: 'all',
												value: false,
											});
											if (state.parameters.aliasses?.length === 0) {
												setValue({
													key: 'parameters',
													nested1: 'aliasses',
													value: [paramBase()],
												});
											}
											return;
										default:
											return;
									}
								}}
							>
								<FormControlLabel
									value="all"
									control={<Radio />}
									label={t`integrations-webhook-addit_dialog-page_data_created-send_data_radio-option_all`}
								/>
								<FormControlLabel
									value="aliasses"
									control={<Radio />}
									label={t`integrations-webhook-addit_dialog-page_data_created-send_data_radio-option_alias`}
								/>
								<FormControlLabel
									value="only_datafields"
									control={<Radio />}
									label={t`integrations-webhook-addit_dialog-page_data_created-send_data_radio-option_specific`}
								/>
							</RadioGroup>
						</FormGroup>

						{state.parameters.aliasses?.length > 0 && (
							<Box mt={1}>
								<FormLabel>
									{t(
										`integrations-webhook-addit_dialog-page_data_created-datafield_option-label_${
											state.parameters.all ? t`rename` : t`send`
										}`
									)}
								</FormLabel>
								{state.parameters.aliasses.map((param, paramIndex) => {
									return (
										<Grid
											container
											key={'param' + paramIndex}
											alignItems="center"
											spacing={1}
										>
											<Grid
												item
												xs={6}
											>
												<DataFieldSelect
													value={param.datafield}
													renderValueTextOnly
													sourceId={state.survey_id}
													onChange={e => {
														setValue({
															key: 'parameters',
															nested1: 'aliasses',
															nested2: paramIndex,
															nested3: 'datafield',
															value: e.target.value,
														});
													}}
												/>
											</Grid>
											<Grid
												item
												xs
											>
												<TextField
													placeholder={t`integrations-webhook-addit_dialog-page_data_created-datafield_option-alias_placeholder`}
													fullWidth
													onChange={e =>
														setValue({
															key: 'parameters',
															nested1: 'aliasses',
															nested2: paramIndex,
															nested3: 'label',
															value: e.currentTarget.value,
														})
													}
													value={param.label}
												/>
											</Grid>
											<Grid item>
												<ActionIconButton
													tooltip={t`integrations-webhook-addit_dialog-page_data_created-datafield_option-alias_button_remove`}
													action="remove_list"
													size="small"
													onClick={() => {
														if (state.parameters.aliasses.length === 1) {
															setValue({
																key: 'parameters',
																nested1: 'aliasses',
																value: [paramBase()],
															});
														} else {
															removeArrayIndex({
																key: 'parameters',
																nested1: 'aliasses',
																index: paramIndex,
															});
														}
													}}
												/>
											</Grid>
										</Grid>
									);
								})}
								<ActionButton
									action="add_list"
									onClick={() =>
										addToArray({
											key: 'parameters',
											nested1: 'aliasses',
											value: paramBase(),
										})
									}
									label={t(
										`integrations-webhook-addit_dialog-page_data_created-datafield_option-button_${
											state.parameters.all ? t`alias` : t`field`
										}`
									)}
								/>
							</Box>
						)}
					</Box>
				)}

			{!state.all_project_data && state.survey_id && (
				<Box mb={2}>
					<FormGroup>
						<FormLabel>{t`integrations-webhook-addit_dialog-page_data_created-send_data_option-label`}</FormLabel>
						<RadioGroup
							value={state.all_data ? 'all' : 'specific'}
							onChange={e => {
								setValue({
									key: 'all_data',
									value: e.target.value === 'all',
								});
								setValue({
									key: 'conditions',
									value: e.target.value === 'all' ? [] : [conditionBase()],
								});
							}}
						>
							<FormControlLabel
								value="all"
								control={<Radio />}
								label={t`integrations-webhook-addit_dialog-page_data_created-send_data_option-label_all`}
							/>
							<FormControlLabel
								value="specific"
								control={<Radio />}
								label={t`integrations-webhook-addit_dialog-page_data_created-send_data_option-label_specific`}
							/>
						</RadioGroup>
					</FormGroup>

					{!state.all_data && (
						<Box mt={1}>
							<FormLabel>{t`integrations-webhook-addit_dialog-page_data_created-send_when_label`}</FormLabel>
							<DataFieldConditions
								conditionArray={state.conditions.map(condition => ({
									datafield_id: condition.datafieldid,
									operator: condition.condition,
									value: condition.target,
								}))}
								surveyId={state.survey_id}
								operatorFn={operators}
								onDataFieldChange={(feedbackConditionIndex, e) => {
									setValue({
										key: 'conditions',
										nested1: feedbackConditionIndex,
										value: {
											datafieldid: e.target.value,
											condition: 'e',
											target: '',
										},
									});
								}}
								onOperatorChange={(feedbackConditionIndex, e) => {
									setValue({
										key: 'conditions',
										nested1: feedbackConditionIndex,
										nested2: 'condition',
										value: e.target.value,
									});
								}}
								onPossibleValueChange={(feedbackConditionIndex, value) => {
									setValue({
										key: 'conditions',
										nested1: feedbackConditionIndex,
										nested2: 'target',
										value: String(value),
									});
								}}
								PossibleValuesProps={{
									multiple: false,
								}}
								onRemoveRow={feedbackConditionIndex => {
									if (state.conditions.length === 1) {
										setValue({
											key: 'conditions',
											value: [conditionBase()],
										});
									} else {
										removeArrayIndex({
											key: 'conditions',
											index: feedbackConditionIndex,
										});
									}
								}}
							/>

							<ActionButton
								onClick={() => addToArray({ key: 'conditions', value: conditionBase() })}
								action="add_list"
								label={t`integrations-webhook-addit_dialog-page_data_created-send_when_button_and`}
							/>
						</Box>
					)}
				</Box>
			)}

			<Box mb={2}>
				<ValidatedField
					component={TextField}
					label={t`integrations-webhook-addit_dialog-page_data_created-input_integration_name_label`}
					placeholder={t`integrations-webhook-addit_dialog-page_data_created-input_integration_name_placeholder`}
					fullWidth
					onChange={e => setValue({ key: 'name', value: e.currentTarget.value })}
					value={state.name}
					validateValue={state.name}
					errorMessage={t`integrations-webhook-addit_dialog-page_data_created-input_integration_name_errormessage`}
					rules={{ required: true }}
					showErrors={clicked}
				/>
			</Box>

			<FormControlLabel
				control={<Checkbox />}
				checked={state.active}
				label={t`integrations-webhook-addit_dialog-page_data_created-checkbox_active`}
				onChange={e => setValue({ key: 'active', value: e.target.checked })}
			/>
		</Box>
	);
}

function DataUserInitStep({ state, clicked, setValue = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box className={classes.blockWrapper}>
			<ValidatedField
				component={TextField}
				label={t`integrations-webhook-addit_dialog-page_data_user_init-input_integration_name_label`}
				placeholder={t`integrations-webhook-addit_dialog-page_data_user_init-input_integration_name_placeholder`}
				fullWidth
				onChange={e => setValue({ key: 'name', value: e.currentTarget.value })}
				value={state.name}
				validateValue={state.name}
				errorMessage={t`integrations-webhook-addit_dialog-page_data_user_init-input_integration_name_errormessage`}
				rules={{ required: true }}
				showErrors={clicked}
			/>
		</Box>
	);
}
