import React from 'react';
import { useAjaxForm } from '@/components/Ajax';

export default function useSaveWebhook({ hook, onSuccess = () => {}, ...props }) {
	return useAjaxForm({
		url: '/integrations/ajax/save-webhooks',
		data: { hooks: JSON.stringify([hook]) },
		onSuccess,
		...props,
	});
}
